import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import layout from "@/components/main-layout.vue";
import layoutEditor from "@/components/mian-layout-editor.vue";
import { getUserInfo } from "@/util/oauth";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  // 无模板
  {
    path: "/login",
    name: "登录",
    meta: {
      isShow: false,
    },
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/export",
    name: "导出",
    meta: {
      isShow: false,
    },
    component: () => import("@/components/export-pdf.vue"),
  },
  {
    path: "/mobiePreview",
    name: "移动端预览",
    meta: {
      isShow: false,
    },
    component: () => import("@/components/mobie-preview.vue"),
  },
  // 空路由
  {
    path: "",
    component: layout,
    redirect: "/home",
  },
  // 编辑器
  {
    path: "",
    name: "看脸大师编辑器",
    component: layoutEditor,
    children: [
      {
        path: "/operateEditor",
        name: "素材编辑器",
        meta: {
          isShow: false,
          icon: "el-icon-s-home",
        },
        component: () => import("@/views/material/operate-editor.vue"),
      },
      {
        path: "/user",
        name: "用户管理",
        meta: {
          isShow: false,
          icon: "el-icon-s-home",
        },
        component: () => import("@/views/system/user-index.vue"),
      },
      {
        path: "/group",
        name: "分组管理",
        meta: {
          isShow: false,
          icon: "el-icon-s-home",
        },
        component: () => import("@/views/system/group-index.vue"),
      },
    ],
  },
  // 业务路由
  {
    path: "",
    name: "看脸大师",
    component: layout,
    children: [
      {
        path: "/home",
        name: "首页",
        meta: {
          isShow: true,
          icon: "el-icon-s-home",
        },
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/workbench",
        name: "待看列表",
        meta: {
          isShow: true,
          icon: "el-icon-s-home",
        },
        component: () => import("@/views/workbench/index.vue"),
      },
      {
        path: "/material",
        name: "素材库",
        meta: {
          isShow: true,
          icon: "el-icon-s-home",
        },
        component: () => import("@/views/material/index.vue"),
      },
      {
        path: "/record",
        name: "已看记录",
        meta: {
          isShow: true,
          icon: "el-icon-s-home",
        },
        component: () => import("@/views/record/index.vue"),
      },
      // {
      //   path: "/lab",
      //   name: "画图测试",
      //   meta: {
      //     isShow: true,
      //     icon: "el-icon-s-home",
      //   },
      //   component: () => import("@/views/home.vue"),
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 守护路由
const whiteList = ["/login"];
router.beforeEach(async (to, from, next) => {
  const userInfo = getUserInfo();
  if (userInfo == "") {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      sessionStorage.clear();
      next("/login");
    }
  } else {
    if (store.getters.userName == "") {
      await store.dispatch("setUserRole");
      const res = await store.dispatch("setUser", { userId: getUserInfo() });
      if (res == 500) {
        sessionStorage.clear();
      }
    }
    if (process.env.NODE_ENV != "development") {
      // addAccessLog(to.fullPath, to.name);
    }
    next();
  }
});

export default router;
