import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { getUserInfo, getUserGroupId } from "@/util/oauth";

// 测试：http://47.99.120.188:6004/api/face
// 正式：https://facemaster.meb.fit/api/face
const service = axios.create({
  baseURL:
    process.env.NODE_ENV == "development" || process.env.NODE_ENV == "stage"
      ? "http://47.99.120.188:6004/api/face" // 测试
      : "https://facemaster.meb.fit/api/face", // 正式
  timeout: 1000 * 120,
});

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers != undefined) {
      config.headers.userId = getUserInfo();
      config.headers.userGroup = getUserGroupId();
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log("API ERROR:" + error);
    return Promise.reject(error);
  }
);

export default service;
