import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "dayjs/locale/zh-cn"; //中文
import locale from "element-plus/lib/locale/lang/zh-cn"; //中文
import "./assets/fonts/font.css";
import "./assets/global.css";
// 通用字体
import "vfonts/Lato.css";
// 等宽字体
import "vfonts/FiraCode.css";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.mount("#app");
