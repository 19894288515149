/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// 用户
import { getUserById, getUserRole, setUserGroupApi } from "@/api/system";
import { userIdPayload, groupObj } from "@/api/apiModel";
import { setUserGroup, getCurrentGroupName } from "@/util/oauth";

const user = {
  state: {
    userId: "",
    userName: "",
    userEmail: "",
    userGroup: [],
    currentGroup: getCurrentGroupName(),
    userRole: [],
    isNewUser: false,
  },
  mutations: {
    SET_USERID: (state: any, name: string): void => {
      state.userId = name;
    },

    SET_NAME: (state: any, name: string): void => {
      state.userName = name;
    },

    SET_EMAIL: (state: any, name: string): void => {
      state.userEmail = name;
    },

    SET_GROUP: (state: any, groupList: string): void => {
      state.userGroup = groupList;
    },

    SET_CURRENTGROUP: (state: any, name: string): void => {
      state.currentGroup = name;
    },

    SET_USERROLE: (state: any, roleList: any): void => {
      state.userRole = roleList;
    },
    SET_ISNEWUSER: (state: any, type: boolean): void => {
      state.isNewUser = type;
    },
  },
  actions: {
    // 设置用户（每次刷新后会重新获取用户信息和分组信息）
    async setUser(context: any, payload: userIdPayload): Promise<number> {
      let res = await getUserById(payload.userId);
      if (res.data != undefined) {
        context.commit("SET_NAME", res.data.realName);
        //
        if (res.data.userGroup[0].proGroupId == null) {
          context.commit("SET_ISNEWUSER", true);
          const param = { userId: res.data.userId, userProGroupArr: ["2"] };
          await setUserGroupApi(param);
          res = await getUserById(payload.userId);
        }
        // end
        context.commit("SET_GROUP", res.data.userGroup);
        context.commit("SET_EMAIL", res.data.userName);
        context.commit("SET_USERID", res.data.userId);

        if (getCurrentGroupName() == "") {
          setUserGroup(res.data.userGroup[0]); // 保存分组，默认是第一个是默认分组
        }
        context.commit("SET_CURRENTGROUP", getCurrentGroupName());
        return 200;
      } else {
        return 500;
      }
    },
    // 获取角色列表
    async setUserRole(context: any): Promise<number> {
      const res = await getUserRole();
      context.commit("SET_USERROLE", res.data);
      return 200;
    },

    // 设置分组
    setCurrentGroup(context: any, payload: groupObj): void {
      setUserGroup(payload);
      context.commit("SET_CURRENTGROUP", payload.proGroupName);
    },
  },
};

export default user;
