import { createStore } from "vuex";
import user from "./modules/user";
import editStatus from "./modules/editStatus";
import getters from "./getters";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: { user, editStatus },
  getters,
});
