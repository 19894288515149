import { groupObj } from "@/api/apiModel";

const userInfo = "userInfo";
const token = "token";
const group = "userGroup";

/**
 * 用户信息
 * @returns
 */
export function getUserInfo(): string {
  return sessionStorage.getItem(userInfo) || "";
}

export function deleteUserInfo(): void {
  sessionStorage.clear();
}

export function setUserInfo(info: string): void {
  return sessionStorage.setItem(userInfo, info);
}

// 分组信息
/**
 * 保存项目(分组)
 * @param group
 * @returns
 */
export function setUserGroup(groupObj: groupObj): void {
  return sessionStorage.setItem(group, JSON.stringify(groupObj));
}

/**
 * 获取当前分组名称
 * @returns
 */
export function getCurrentGroupName(): string {
  const obj = JSON.parse(sessionStorage.getItem(group) as string) as groupObj;
  if (obj != null) {
    return obj.proGroupName;
  } else {
    return "";
  }
}

/**
 * 获取当前项目组ID
 * @returns
 */
export function getUserGroupId(): string {
  if (sessionStorage.getItem(group) != null) {
    try {
      const obj = JSON.parse(sessionStorage.getItem(group) || "{}");
      return obj.proGroupId;
    } catch (e) {
      return "";
    }
  } else {
    return "";
  }
}

/**
 * 获取token
 * @returns
 */
export function getToken(): string {
  return sessionStorage.getItem(token) || "";
}

/**
 * deepclone
 * @param source
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deepClone(source: any): any {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments" + "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys: string) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
/**
 * 时间戳转时间
 * @param timeStamp
 * @returns
 */
export function toDateFilter(timeStamp: string): string {
  function add0(m: any) {
    return m < 10 ? "0" + m : m;
  }
  const time = new Date(timeStamp);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const mm = time.getMinutes();
  const s = time.getSeconds();
  return (
    y +
    "-" +
    add0(m) +
    "-" +
    add0(d) +
    " " +
    add0(h) +
    ":" +
    add0(mm) +
    ":" +
    add0(s)
  );
}
