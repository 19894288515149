<!--编辑器模板-->
<template>
  <div>
    <main-top-bar></main-top-bar>
    <div class="content-container">
      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import mainTopBar from "@/components/main-top-bar.vue";

export default defineComponent({
  name: "layoutEditor",
  components: {
    mainTopBar,
  },
  setup() {
    const isShowSide = ref(true);
    return {
      isShowSide,
    };
  },
});
</script>
<style scoped>
.content-container {
  display: flex;
  justify-content: flex-start;
}
.content-container > div:nth-child(1) {
  position: fixed;
  top: 50px;
  width: 100vw;
  bottom: 0;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
