<template>
  <div class="side-menu">
    <div>
      <n-menu @update:value="handleUpdateValue" :options="routerList"></n-menu>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import router from "@/router/index";
import { useRouter } from "vue-router";
import { NMenu } from "naive-ui";

export default defineComponent({
  name: "mainSideBar",
  components: {
    NMenu,
  },
  setup() {
    let routerList = ref([]);
    const initMenu = () => {
      routerList.value = [];
      for (const item of router.getRoutes()) {
        if (item.children == undefined || item.children.length == 0) {
          if (item.meta.isShow) {
            item.label = item.name;
            item.key = item.path;
            item.children = undefined;
            routerList.value.push(item);
          }
        }
      }
    };
    const url = useRouter();
    const handleUpdateValue = (key, item) => {
      url.push(item);
    };

    onMounted(() => {
      initMenu();
    });
    return {
      routerList,
      handleUpdateValue,
    };
  },
});
</script>
<style scoped>
.side-menu {
  min-width: 10vw;
  min-height: 100vh;
  background-color: #f8f8f8;
  position: fixed;
  top: 50px;
}
.menu {
  margin-top: 20px;
  background-color: #f8f8f8;
}
.menu-item {
  font-size: 16px;
  font-weight: 100;
  color: #424242;
  margin-left: 20px;
}
</style>
