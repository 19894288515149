
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { deleteUserInfo } from "@/util/oauth";
import { useRouter, useRoute } from "vue-router";
import { userRoleModel } from "@/api/systemModel";

import IntroJs from "intro.js";
import "intro.js/introjs.css";
import "intro.js/themes/introjs-modern.css";

export default defineComponent({
  name: "mainTopBar",
  setup() {
    const store = useStore();
    const userName = computed(() => {
      return store.state.user.userName || "-";
    });
    // 当前组
    const currentGroup = computed(() => {
      return store.state.user.currentGroup || "";
    });
    // 当前用户分组列表
    const userGroup = computed(() => {
      return store.state.user.userGroup || [{ name: "无分组", id: -1 }];
    });
    // 权限列表
    const dropList = computed(() => {
      return store.state.user.userRole as Array<userRoleModel>;
    });

    let isShowGroup = ref(true);

    const logoImg = ref("");
    const userImg = ref("");
    logoImg.value = require("@/assets/logo.png");
    userImg.value = require("@/assets/user.png");
    const title = ref("看脸大师");

    const changeGroup = (item: string): void => {
      store.dispatch("setCurrentGroup", item);
      location.reload();
    };

    let url = useRouter();
    const sysManage = (type: string) => {
      if (type == "user") {
        const { href } = url.resolve({
          path: `/user`,
          query: {
            manage: "true",
          },
        });
        window.open(href, "_blank");
      } else {
        const { href } = url.resolve({
          path: `/group`,
          query: {
            manage: "true",
          },
        });
        window.open(href, "_blank");
      }
    };

    const logOut = (): void => {
      ElMessageBox.confirm("是否退出看脸大师？", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "退出",
        type: "warning",
      })
        .then(() => {
          deleteUserInfo();
          url.push("/login");
        })
        .catch(() => {
          ElMessage.info("已取消");
        });
    };
    const allSteps = [
      {
        element: "#one",
        intro:
          "首次登陆后默认 美学设计 分组，有分组需求请企微联系 杨鑫(yangxin@meb.com)", //这是引导提示内容
        position: "bottom",
      },
    ];

    const initPageIntro = () => {
      const curIntro = IntroJs();
      curIntro.setOptions({
        prevLabel: `上一步`,
        nextLabel: `下一步`,
        // skipLabel: `跳过`,
        doneLabel: `完成`,
        tooltipPosition: `bottom` /* 引导说明框相对高亮说明区域的位置 */,
        hidePrev: `true`, // 隐藏第一步中的上一个按钮
        tooltipClass: `` /* 引导说明文本框的样式 */,
        highlightClass: `` /* 说明高亮区域的样式 */,
        exitOnOverlayClick: false /* 是否允许点击空白处退出 */,
        showStepNumbers: false /* 是否显示说明的数据步骤*/,
        keyboardNavigation: false /* 是否允许键盘来操作 */,
        showButtons: true /* 是否按键来操作 */,
        showBullets: true /* 是否使用点点点显示进度 */,
        showProgress: false /* 是否显示进度条 */,
        scrollToElement: true /* 是否滑动到高亮的区域 */,
        overlayOpacity: 0.6 /* 遮罩层的透明度 */,
        positionPrecedence: [
          `bottom`,
          `top`,
          `right`,
          `left`,
        ] /* 当位置选择自动的时候，位置排列的优先级 */,
        disableInteraction: false /* 是否禁止与元素的相互关联 */,
        hintPosition: "top-middle",
        steps: allSteps,
      });
      curIntro.oncomplete(() => {
        console.log(`oncomplete`);
      });
      curIntro.onexit(() => {
        console.log(`onexit`);
      });
      curIntro.onchange(() => {
        console.log(`onchange`);
      });
      curIntro.start();
    };
    onMounted(() => {
      const route = useRoute();
      if (route.query.manage != null) {
        isShowGroup.value = false;
      }
      if (store.state.user.isNewUser) {
        initPageIntro();
      }
    });
    return {
      logoImg,
      userImg,
      title,
      userName,
      userGroup,
      currentGroup,
      logOut,
      changeGroup,
      sysManage,
      isShowGroup,
      dropList,
    };
  },
});
