/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const editStatus = {
  state: {
    orderType: sessionStorage.getItem("orderType"),
    isSave: false,
  },
  mutations: {
    //value: workbench||record
    SET_ORDERTYPE: (state: any, value: string): void => {
      sessionStorage.setItem("orderType", value);
      state.orderType = value;
    },
    // 是否保存
    SET_ISSAVE: (state: any, value: boolean): void => {
      state.isSave = value;
    },
  },
  actions: {},
};

export default editStatus;
