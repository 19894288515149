import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b66a69b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_top_bar = _resolveComponent("main-top-bar")!
  const _component_main_side_bar = _resolveComponent("main-side-bar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_main_top_bar),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_ctx.isShowSide)
          ? (_openBlock(), _createBlock(_component_main_side_bar, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}