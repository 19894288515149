
import { defineComponent } from "vue";

export default defineComponent({
  name: "APP",
  components: {},
  setup() {
    console.log(process.env.NODE_ENV);
  },
});
