
import { defineComponent, ref } from "vue";
import mainTopBar from "@/components/main-top-bar.vue";
import mainSideBar from "@/components/main-side-bar.vue";

export default defineComponent({
  name: "mainLayout",
  components: {
    mainTopBar,
    mainSideBar,
  },
  setup() {
    const isShowSide = ref(true);
    return {
      isShowSide,
    };
  },
});
