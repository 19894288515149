import request from "@/util/request";
import { GroupModel, UserGroupModel } from "./systemModel";

/**
 * 获取用户信息
 * @param id
 * @returns
 */
export function getUserById(id: string): any {
  return request({
    url: `/getuserbyid?id=${id}`,
    method: "get",
  });
}

/**
 * 获取用户列表
 * @returns
 */
export function getUserList(): any {
  return request({
    url: "/getuserlist",
    method: "get",
  });
}

/**
 * 获取分组列表
 * @returns
 */
export function getGroupList(): any {
  return request({
    url: "/getprogrouplist",
    method: "get",
  });
}

/**
 * 移除订单
 * 对接人：wangyihuai@meb.com
 * create by wangyihuai@meb.com
 * @param id 订单id
 */
export function removeOrderApi(id: any): any {
  return request({
    url: "/removeOrder/" + id,
    method: "get",
  });
}

/**
 * 删除分组
 * @param id
 * @returns
 */
export function deleteGroupByid(id: number | string): any {
  return request({
    url: "/deleteprogroup?id=" + id,
    method: "post",
  });
}

/**
 * 新建分组
 * @param id
 * @returns
 */
export function addGroupApi(query: GroupModel): any {
  return request({
    url: "/saveprogroup",
    method: "post",
    data: query,
  });
}

/**
 * 更新分组
 * @param id
 * @returns
 */
export function updateGroupApi(query: GroupModel): any {
  const param = { json: JSON.stringify(query) };
  return request({
    url: "/changeprogroupuser",
    method: "post",
    data: param,
  });
}

/**
 * 设置用户分组
 * @param query
 * @returns
 */
export function setUserGroupApi(query: UserGroupModel): any {
  const param = { json: JSON.stringify(query) };
  return request({
    url: "/changeuserprogroup",
    method: "post",
    data: param,
  });
}

/**
 * 获取用户权限
 * @returns
 */
export function getUserRole(): any {
  return request({
    url: "/getmenulist",
    method: "get",
  });
}
